import request from "@/utils/request";


// 图片列表
export function getProtfolioList(pageNum, pageSize) {
    return request({
        // url: '/system/portfolio/list?pageNum=1&pageSize=10'
        url:
            "/system/portfolio/list?pageNum=" +
            pageNum +
            "&pageSize=" +
            pageSize,
        method: "get",
        type: true,
    })
}

// 新闻列表
export function getNewsList(pageNum, pageSize) {
    return request({
        // url: '/system/news/list?pageNum=1&pageSize=10'
        url:
            "/system/news/list?pageNum=" +
            pageNum +
            "&pageSize=" +
            pageSize,
        method: "get",
        type: true,
    })
}

// 提交评论
export function bookInspection(data) {
    return request({
        url: "/houseInspection/BookInspection",
        method: "post",
        data,
        type: true,
    });
}
