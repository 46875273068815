<template>
  <div class="serivices">
    <headerTop />
    <breadcrumb :breadList="breadList" />
    <div class="s-content">
      <div class="s-img-box">
        <img :src="img" alt="" />
      </div>
      <div class="s-content-box">
        <div>
          <div class="gradient-button font" style="text-indent: 24px;">Overview</div>
          <p class="s-content-desc">
            <img style="height: 23px; width: 23px;" src="../../assets/home/icon_Finish.png" alt="">Inspect the structural integrity of houses and condos for maximum safety.
          </p>
        </div>
      </div>
    </div>
    <p class="s-waterfall-title font"><span
        style="height: 45px; width: 10px; border-radius: 0px 100px 100px 0px; background-color: #007562; display: block;"></span>Our
      equipment</p>
    <div class="waterfall" ref="waterfall">
      <div class="img-box default-card-animation" v-for="(item, index) in imgsArr_c" :key="index"
        :style="{ width: imgWidth + 'px', height: item._height + 'px' }" ref="imgBox">
        <img :data-src="item.src" />
      </div>
    </div>
    <footerBottom />
  </div>
</template>
<script>
import headerTop from "../../components/header.vue";
import breadcrumb from "../../components/breadcrumb.vue";
import footerBottom from "../../components/footer.vue";
export default {
  components: {
    headerTop,
    breadcrumb,
    footerBottom,
  },
  data() {
    return {
      breadList: ["Home Delivery Service & Inspection", "Home & Condo Inspection List", "Structural"],
      img: require("../../assets/serivice/people.jpg"),
      imgsArr: [
        {
          src: require("../../assets/new/icon_1.jpg"),
          href: "https://www.baidu.com",
          info: "我是第一张图片",
          headerText: "测试",
        },
        {
          src: require("../../assets/new/icon_2.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字asdasdasdasdasdasasdasd",
          headerText: "测试",
        },
        {
          src: require("../../assets/new/icon_3.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字",
        },
        {
          src:require("../../assets/new/icon_4.jpg"),
          href: "https://www.baidu.com",
          info: "我是第一张图片",
          headerText: "测试",
        },
        {
          src: require("../../assets/new/icon_5.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字asdasdasdasdasdasasdasd",
          headerText: "测试",
        },
        {
          src:require("../../assets/new/icon_6.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字",
        },
        {
          src: require("../../assets/new/icon_7.jpg"),
          href: "https://www.baidu.com",
          info: "我是第一张图片",
          headerText: "测试",
        },
        {
          src: require("../../assets/new/icon_8.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字asdasdasdasdasdasasdasd",
          headerText: "测试",
        },
        {
          src: require("../../assets/new/icon_9.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字",
        },
        {
          src: require("../../assets/new/icon_10.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字asdasdasdasdasdasasdasd",
          headerText: "测试",
        },
        {
          src: require("../../assets/new/icon_11.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字",
        },
        {
          src: require("../../assets/new/icon_12.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字asdasdasdasdasdasasdasd",
          headerText: "测试",
        },
        {
          src: require("../../assets/new/icon_13.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字",
        },
        {
          src: require("../../assets/new/icon_14.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字asdasdasdasdasdasasdasd",
          headerText: "测试",
        },
        {
          src: require("../../assets/new/icon_15.jpg"),
          href: "https://www.baidu.com",
          info: "一些图片描述文字",
        },
      ],
      imgsArr_c: [], // 渲染的图片
      imgCol: 6, // 图片列数
      imgGap: 5, // 图片间间隔
      loadedCount: 0,
      imgBoxEls: [], // 所有 img-box 元素
      beginIndex: 0,
      colsHeightArr: [], // 保存当前每一列的高度
      reachBottomDistance: 20, // 滚动触底距离，触发加载新图片
      viewHeight: 0, // 窗口视图大小
       list: [
        {
          id: "1",
          imgSrc: require("../../assets/serivice/icon_1.png"),
          content: "Hood Inspection",
          desc: "range hood, ventilation fans and cleanliness",
          name: 'hood'
        },
        {
          id: "2",
          imgSrc: require("../../assets/serivice/icon_2.png"),
          content: "Door and Window Inspection",
          desc: "automatic door system and digital door lock",
          name: 'door'
        },
        {
          id: "3",
          imgSrc: require("../../assets/serivice/icon_3.png"),
          content: "Electrical Inspection",
          desc: "electrical inspection interior and exterior",
          name: 'electrical'
        },
        {
          id: "4",
          imgSrc: require("../../assets/serivice/icon_4.png"),
          content: "Structural Inspection",
          desc: "foundation, structure and inside wall inspection ",
          name: 'structural'
        },
        {
          id: "5",
          imgSrc: require("../../assets/serivice/icon_4.png"),
          content: "Internet and CCTV Inspection",
          desc: "internet system and CCTV system",
          name: 'internet'
        },
        {
          id: "6",
          imgSrc: require("../../assets/serivice/icon_6.png"),
          content: "Roof Inspection",
          desc: "roof and rain gutters",
          name: 'roof'
        },
        {
          id: "7",
          imgSrc: require("../../assets/serivice/icon_7.png"),
          content: "Plumbing Inspection",
          desc:
            "water meters, water storage tanks, water pumps, sanitary ware, wastewater treatment tanks, grease traps, and drainage systems",
          name: 'plumbing'
        },
        {
          id: "8",
          imgSrc: require("../../assets/serivice/icon_8.png"),
          content: "Air conditioning Inspection",
          desc: "foundation, structure and inside wall inspection ",
          name:'air'
        },
        {
          id: "9",
          imgSrc: require("../../assets/serivice/icon_9.png"),
          content: "Stair Inspection",
          desc: "stair for public area",
          name:'stair'
        },
      ],
    };
  },
  computed: {
    // 容器 waterfall 的宽度
    waterfallWidth() {
      return this.$refs["waterfall"].clientWidth;
    },
    // 图片宽度
    imgWidth() {
      return this.colWidth - 2 * this.imgGap;
    },
    // 列宽度
    colWidth() {
      return this.waterfallWidth / this.colNum;
    },
    // 列数
    colNum() {
      return this.imgCol;
    },
  },
  watch: {
    imgsArr(newVal, oldVal) {
      if (
        this.imgsArr_c.length > newVal.length ||
        (this.imgsArr_c.left > 0 && newVal[0] && !newVal[0]._height)
      )
        this.reset();
      this.preLoad();
    },
  },
  methods: {
    // 预加载 设置图片宽高
    preLoad() {
      // forEach 无法通过 item 直接修改数组元素，需用数组下标修改
      this.imgsArr.forEach((item, index) => {
        if (index < this.loadedCount) return;
        if (!item.src) {
          this.imgsArr[index]._height = "0";
          ++this.loadedCount;
          if (this.imgsArr.length === this.loadedCount) {
            this.preloaded();
          }
        } else {
          let img = new Image();
          img.src = item.src;
          img.onload = img.onerror = (e) => {
            // 若加载失败则设置图片高度与宽度一致，加载成功则动态计算图片高度
            this.imgsArr[index]._height =
              e.type === "load"
                ? Math.round(this.imgWidth * (img.height / img.width))
                : this.imgWidth;
            if (e.type === "error") {
              this.imgsArr[index]._error = true;
            }
            ++this.loadedCount;
            if (this.imgsArr.length === this.loadedCount) {
              this.preloaded();
            }
          };
        }
      });
    },
    preloaded() {
      this.imgsArr_c = [].concat(this.imgsArr);
      this.$nextTick(() => {
        this.waterfall();
      });
    },
    // waterfall 布局
    waterfall() {
      // 等到整个视图都渲染完毕再执行
      this.imgBoxEls = this.$refs["imgBox"];
      if (!this.imgBoxEls) return;
      let top, left, height;
      if (this.beginIndex === 0) this.colsHeightArr = [];
      for (let i = this.beginIndex; i < this.imgBoxEls.length; ++i) {
        if (!this.imgBoxEls[i]) return;
        height = this.imgBoxEls[i].offsetHeight;
        // 第一行
        if (i < this.colNum) {
          this.colsHeightArr.push(height);
          top = 0;
          left = i * this.colWidth;
        } else {
          // 找到最低的高度和其索引
          let minHeight = Math.min.apply(null, this.colsHeightArr);
          let minIdx = this.colsHeightArr.indexOf(minHeight);
          top = minHeight;
          left = minIdx * this.colWidth;
          this.colsHeightArr[minIdx] += height;
        }
        // 设置 img-box 位置
        this.imgBoxEls[i].style.top = top + "px";
        this.imgBoxEls[i].style.left = left + "px";
        // 当前图片在窗口内，则加载
        if (top < this.viewHeight) {
          let imgEl = this.imgBoxEls[i].children[0];
          imgEl.src = imgEl.getAttribute("data-src");
          imgEl.style.opacity = 1;
          imgEl.style.transform = "scale(1)";
        }
      }
      this.beginIndex = this.imgBoxEls.length;
    },
    reset() {
      this.imgsArr_c = [];
      this.beginIndex = 0;
      this.loadedCount = 0;
    },
    // 滚动触底事件
    scrollFn() {
      let minHeight = Math.min.apply(null, this.colsHeightArr);
      // 滚动条滚动的高度
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 到达最底层的高度最低的一列，则触发 handleReachBottom 方法
      if (scrollTop + this.viewHeight > minHeight - this.reachBottomDistance) {
        this.handleReachBottom();
      }
      // 图片懒加载
      this.imgBoxEls.forEach((imgBoxEl, index) => {
        let imgEl = imgBoxEl.children[0];
        // 若已加载，则跳过
        if (imgEl.src) return;
        // 当前图片所处的高度
        let top = imgBoxEl.style.top;
        top = Number.parseFloat(top.slice(0, top.length - 2));
        // 图片已到达可视范围，则加载
        if (scrollTop + this.viewHeight > top) {
          imgEl.src = imgEl.getAttribute("data-src");
          imgEl.style.opacity = 1;
          imgEl.style.transform = "scale(1)";
        }
      });
    },
    scroll() {
      window.onscroll = this.throttle(this.scrollFn, 500);
    },
    handleReachBottom() {
      this.imgsArr = this.imgsArr.concat(this.imgsArr);
      console.log("--this.imgsArr--", this.imgsArr);
    },
    // 节流函数
    throttle(fn, time) {
      let canRun = true;
      return function () {
        if (!canRun) return;
        canRun = false;
        setTimeout(() => {
          fn.apply(this);
          canRun = true;
        }, time);
      };
    },
  },
  mounted() {
    this.viewHeight =
      document.documentElement.clientHeight == 0
        ? document.body.clientHeight
        : document.documentElement.clientHeight;
    this.preLoad();
    // this.scroll();
  },
};
</script>
<style scoped>
.serivices {
  background: #fff;
  padding-top: 10px;
  box-sizing: border-box;
}

.s-content {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
}

.s-img-box {
  width: 51%;
}

.gradient-button {
  width: 197px;
  height: 39px;
  border: none;
  color: white;
  /* text-align: center; */
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  line-height: 39px;
  margin-bottom: 20px;

  /* 为按钮添加线性渐变背景 */
  background: linear-gradient(90deg, #007562 0%, rgba(0, 117, 98, 0) 100%);
}

.s-content-desc img {
  margin-right: 10px;
}

.s-content-desc {
  font-size: 16px !important;
  color: #333;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  align-items: first baseline;
}
.s-content-desc img {
  margin-right: 10px;
}

.s-content-box p {
  font-size: 18px;
  /* line-height: 32px; */
  display: flex;
  align-items: flex-start;
  font-family: inherit;
  /* 继承父元素的字体 */
  font-size: inherit;
  /* 继承父元素的字体大小 */
  line-height: inherit;
  /* 继承父元素的行高 */
  margin: 30px 0;
}

.s-content-box {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.s-book-btn {
  border: none;
  color: white;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  background: #007562;
  width: 200px;
  height: 30px;
  line-height: 30px;
  border-radius: 6px;
}

.s-waterfall-title {
  width: 90%;
  margin: 0 auto;
  font-size: 30px;
  color: #007562;
  margin-top: 50px;
  margin-bottom: 55px;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.waterfall {
  width: 90%;
  position: relative;
  margin: 0 auto;
  height: 600px;
  overflow-y: scroll;
}
/* .s-content-box p{
  font-size: 18px;
  line-height: 32px;
} */
@keyframes show-card {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.img-box {
  position: absolute;
  border-radius: 10px;
  padding: 5px;
  padding-left: 0;
}

img {
  width: 100%;
  border-radius: 10px;
  /* opacity: 0; */
  /* transform: scale(0.5); */
  transition: all 0.6s;
  transition-delay: 0.1s;
}

.s-our-serive {
  width: 90%;
  margin: 0 auto;
}

.s-our-serive-title {
  margin: 50px 0;
  font-size: 30px;
  color: #007562;
  display: flex;
  flex-direction: row;
  text-indent: 20px;
}

.s-our-serive-content {
  height: 360px;
  background: #f3f8f8;
  border-radius: 10px;
  border: 1px solid #007562;
  margin-bottom: 50px;
  padding: 52px 28px 50px 28px;
  box-sizing: border-box;
}

.s-our-serive-content-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.s-our-serive-content-item {
  width: 32%;
  display: flex;
  margin-bottom: 40px;
  cursor: pointer;
}

.s-our-serive-content-item-img {
  width: 48px;
  height: 48px;
  background: #007562;
  margin-right: 22px;
}

.s-our-serive-content-item-img img {
  width: 100%;
  height: 100%;
}

.s-our-serive-content-item-content {
  cursor: pointer;
  font-size: 16px;
  color: #000000;
}

.s-our-serive-content-item-desc {
  cursor: pointer;
  font-size: 12px;
  color: #333333;
  margin-top: 11px;
}
.img-box img{
  height: 100%;
  width: 100%;
}
@font-face {
  font-family: 'MyCustomFont';
  src: url('../../assets/fonts/Avenir\ Next.ttc') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.font{
  font-family: 'MyCustomFont', sans-serif;
}
</style>
