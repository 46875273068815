<template>
  <div class="breadcrumb">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-for="(item,index) in breadList" :key="index">{{item}}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
export default {
    props: ["breadList"],
    data() {
        return {

        }
    }
}
</script>
<style scoped>
.breadcrumb {
    margin-left: 166px;
    margin-right: 166px;
    background-color: #F5F5F5;
    padding: 11px 17px;
    box-sizing: border-box;
    margin-bottom: 20px;
    border-radius: 5px;
    font-size: 20px;
    margin-top: 20px;
}
</style>
